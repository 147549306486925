import React, { useState, useEffect } from 'react'
import { Link } from '@reach/router'
import { scrollTo } from 'react-static'
import styled from '@emotion/styled'
import classNames from 'classnames'
/* @jsx jsx */
// eslint-disable-next-line no-unused-vars
import { jsx, css } from '@emotion/core'
import logo from '../images/logo.svg'

const NavigationBar = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  z-index: 2;
  color: #000;
  display: flex;
  user-select: none;

  background: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;

  &.on-top {
    background: transparent;
    box-shadow: none;
  }

  &.open {
    box-shadow: none;
    background: #fff;
  }
`

const logoStyle = css`
  flex: 1;
  display: flex;
  padding-left: 24px;
  align-items: center;

  img {
    height: 45px;
  }
`

const NavigationMenu = styled.div`
  position: fixed;
  flex-direction: column;
  top: 70px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  margin: 0;
  padding: 0;
  background: #fff;
  transform-origin: 0% 0%;
  transform: translate(100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  justify-content: space-around;
  overflow-y: scroll;

  &.open {
    transform: translate(0);
  }

  @media (min-width: 64em) {
    display: none;
  }
`

const NavigationMenuContent = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  display: block;
  padding: 0;
  margin: 0;
  height: 100%;
  min-height: 25rem;
`

const button = css`
  width: 40px;
  height: 32px;
  position: absolute;
  top: 20px;
  right: 24px;
  margin: 0;
  padding: 0;

  @media (min-width: 64em) {
    display: none;
  }
`

const MenuButton = styled.button`
  ${button}
  cursor: pointer;
  z-index: 2;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  background: none;

  @media (min-width: 64em) {
    display: none;
  }
`

const MenuButtonBar = styled.span`
  display: block;
  width: 32px;
  height: 4px;
  position: relative;
  background: #275236;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 50% 0;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    opacity 0.55s ease;

  .open & {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  &:not(:last-of-type) {
    margin-bottom: 5px;
  }

  &:first-of-type {
    transform-origin: 0% 0%;

    .open & {
      opacity: 1;
      transform: translate(7px, -1px) rotate(45deg);
      background: #232323;
    }
  }

  &:last-of-type {
    transform-origin: 0% 100%;

    .open & {
      opacity: 1;
      transform: translate(7px, 1px) rotate(-45deg);
      background: #232323;
    }
  }
`

const linkStyle = css`
  color: #000;
  flex: 1;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  box-sizing: border-box;
  margin: 2rem 0;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:last-of-type {
    font-weight: 500;
    color: #275236;
    padding-top: 2rem;
  }

  @media (min-width: 64em) {
    font-weight: 500;
    font-size: 0.8rem;

    &:last-of-type {
      padding-top: 0;
    }
  }
`

const QuickContact = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    font-size: 0.7rem;
    flex: 1;
    padding: 0 0.7rem;
    text-align: center;
    color: initial;
    text-decoration: none;
  }
`

const OnDesktop = styled.div`
  display: none;

  @media (min-width: 64em) {
    display: flex;
    flex: 1;
  }
`

export default () => {
  const [isOpen, setOpen] = useState(false)
  const [onTop, setOnTop] = useState(true)

  useEffect(() => {
    function onScroll() {
      const { pageYOffset } = window
      setOnTop(pageYOffset < 24)
    }

    onScroll()
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    const { hash } = window.location
    window.location.hash = ''
    setTimeout(() => {
      window.location.hash = hash
    }, 100)
  }, [])

  return (
    <>
      <NavigationBar className={classNames({ 'on-top': onTop, open: isOpen })}>
        <Link
          css={logoStyle}
          to="/"
          onClick={(ev) => {
            if (!isOpen) {
              ev.preventDefault()
              scrollTo(0)
            }

            setOpen(false)
          }}
        >
          <img src={logo} alt="logo" />
        </Link>

        <OnDesktop>
          <Link css={linkStyle} to="/#cegunk" onClick={() => setOpen(false)}>
            Cégünk
          </Link>
          <Link css={linkStyle} to="/#szolgaltatasaink" onClick={() => setOpen(false)}>
            Szolgáltatásaink
          </Link>
          <Link css={linkStyle} to="/munkaink" onClick={() => setOpen(false)}>
            Munkáink
          </Link>
          <Link css={linkStyle} to="/munkalehetoseg" onClick={() => setOpen(false)}>
            Munkalehetőség
          </Link>
          <Link css={linkStyle} to="/ajanlatkeres" onClick={() => setOpen(false)}>
            Ajánlatkérés ➤
          </Link>
        </OnDesktop>

        <MenuButton onClick={() => setOpen(!isOpen)} className={classNames({ open: isOpen })}>
          <MenuButtonBar />
          <MenuButtonBar />
          <MenuButtonBar />
        </MenuButton>

        {/* <PhoneIcon>&#9742;</PhoneIcon> */}
      </NavigationBar>

      <NavigationMenu className={classNames({ open: isOpen })}>
        <NavigationMenuContent>
          <Link css={linkStyle} to="/#cegunk" onClick={() => setOpen(false)}>
            Cégünk
          </Link>
          <Link css={linkStyle} to="/#szolgaltatasaink" onClick={() => setOpen(false)}>
            Szolgáltatásaink
          </Link>
          <Link css={linkStyle} to="/munkaink" onClick={() => setOpen(false)}>
            Munkáink
          </Link>
          <Link css={linkStyle} to="/munkalehetoseg" onClick={() => setOpen(false)}>
            Munkalehetőség
          </Link>
          <Link css={linkStyle} to="/ajanlatkeres" onClick={() => setOpen(false)}>
            Ajánlatkérés ➤
          </Link>

          <QuickContact>
            <a href="tel:+36204504906">+36 20 4504 906</a>
            <span> | </span>
            <a href="mailto:standard.kft@freemail.hu">standard.kft@freemail.hu</a>
          </QuickContact>
        </NavigationMenuContent>
      </NavigationMenu>
    </>
  )
}
