import React, { useEffect } from 'react'
import { Root, Routes, scrollTo } from 'react-static'
import styled from '@emotion/styled'
import 'normalize.css'

import Navigation from './containers/Navigation'

import './app.css'

const breakpoints = [64]

const mq = breakpoints.map((bp) => `@media (min-width: ${bp}em)`)

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  background: #fff;
  text-align: center;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  z-index: 9999;
  font-size: 2em;
  font-weight: 100;
  text-transform: uppercase;

  ${mq[0]} {
    visibility: visible;
  }
`

const Content = styled.div`
  position: relative;
  min-height: calc(100vh - 70px);
  padding-bottom: 2rem;

  @media (min-width: 64em) {
    // padding-top: 90px;
  }
`

const QuickContact = styled.footer`
  position: absolute;
  right: 0;
  bottom: 40px;
  left: 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;

  a {
    font-size: 0.7rem;
    flex: 1;
    padding: 0 0.7rem;
    text-align: center;
    color: initial;
    text-decoration: none;
  }
`

function App() {
  useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }, [])

  if (typeof window !== 'undefined') {
    useEffect(() => {
      const { hash } = window.location
      if (hash) {
        scrollTo(document.getElementById(hash.replace('#', ''))).catch(console.error)
      }
    }, [window.location.hash])
  }

  return (
    <Root>
      <Navigation />
      <Content>
        <Routes />
        <QuickContact>
          <a href="tel:+36204504906">+36 20 4504 906</a>
          <span> | </span>
          <a href="mailto:standard.kft@freemail.hu">standard.kft@freemail.hu</a>
        </QuickContact>
      </Content>
    </Root>
  )
}

export default App
