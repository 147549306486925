
import universal, { setHasBabelPlugin } from '/opt/build/repo/node_modules/react-static/node_modules/react-universal-component/dist/index.js'


setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
}

const t_0 = universal(import('../src/pages/404.js'), universalOptions)
const t_1 = universal(import('../src/pages/ajanlatkeres-sikeres.js'), universalOptions)
const t_2 = universal(import('../src/pages/ajanlatkeres.js'), universalOptions)
const t_3 = universal(import('../src/pages/badogos-munkak.js'), universalOptions)
const t_4 = universal(import('../src/pages/bontasi-munkalatok.js'), universalOptions)
const t_5 = universal(import('../src/pages/burkolas.js'), universalOptions)
const t_6 = universal(import('../src/pages/fakivagas.js'), universalOptions)
const t_7 = universal(import('../src/pages/festes.js'), universalOptions)
const t_8 = universal(import('../src/pages/futesszereles.js'), universalOptions)
const t_9 = universal(import('../src/pages/generalkivitelezes.js'), universalOptions)
const t_10 = universal(import('../src/pages/gipszkartonozas.js'), universalOptions)
const t_11 = universal(import('../src/pages/homlokzat-tisztitas.js'), universalOptions)
const t_12 = universal(import('../src/pages/hoszigeteles.js'), universalOptions)
const t_13 = universal(import('../src/pages/index.js'), universalOptions)
const t_14 = universal(import('../src/pages/ko-es-gipszmunkak.js'), universalOptions)
const t_15 = universal(import('../src/pages/komuves-munkak.js'), universalOptions)
const t_16 = universal(import('../src/pages/lepcso-kivitelezes.js'), universalOptions)
const t_17 = universal(import('../src/pages/munkaink.js'), universalOptions)
const t_18 = universal(import('../src/pages/munkalehetoseg.js'), universalOptions)
const t_19 = universal(import('../src/pages/muszaki-vezetes.js'), universalOptions)
const t_20 = universal(import('../src/pages/tetoepites.js'), universalOptions)
const t_21 = universal(import('../src/pages/tetoszigeteles.js'), universalOptions)
const t_22 = universal(import('../src/pages/villanyszereles.js'), universalOptions)


// Template Map
export default {
  '../src/pages/404.js': t_0,
'../src/pages/ajanlatkeres-sikeres.js': t_1,
'../src/pages/ajanlatkeres.js': t_2,
'../src/pages/badogos-munkak.js': t_3,
'../src/pages/bontasi-munkalatok.js': t_4,
'../src/pages/burkolas.js': t_5,
'../src/pages/fakivagas.js': t_6,
'../src/pages/festes.js': t_7,
'../src/pages/futesszereles.js': t_8,
'../src/pages/generalkivitelezes.js': t_9,
'../src/pages/gipszkartonozas.js': t_10,
'../src/pages/homlokzat-tisztitas.js': t_11,
'../src/pages/hoszigeteles.js': t_12,
'../src/pages/index.js': t_13,
'../src/pages/ko-es-gipszmunkak.js': t_14,
'../src/pages/komuves-munkak.js': t_15,
'../src/pages/lepcso-kivitelezes.js': t_16,
'../src/pages/munkaink.js': t_17,
'../src/pages/munkalehetoseg.js': t_18,
'../src/pages/muszaki-vezetes.js': t_19,
'../src/pages/tetoepites.js': t_20,
'../src/pages/tetoszigeteles.js': t_21,
'../src/pages/villanyszereles.js': t_22
}

export const notFoundTemplate = "../src/pages/404.js"
